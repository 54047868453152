import React, {useState} from 'react';
import { Button, TextField, Box, Paper, Grid, Typography } from '@mui/material';
import UploadFile from '../UploadFile';
import { buttonStyle, paperStyle } from '../../utils/utils';
import { postDocument } from '../../axios';
import { NotificationModal } from '../NotificationModal';


export default function FormDocs(props) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [dataFile, setDataFile] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const handleOpenModal = (message) => {
        setModalContent(message);
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', name);
        formData.append('description', description);
        formData.append('file', dataFile);
        
        try {
            const response = postDocument(formData);
        } catch (error) {
            console.log(error);
        } finally {
            setName('');
            setDescription('');
            setDataFile(null);
        }
    };

    return (
        <React.Fragment>
            <Paper sx={{...paperStyle, marginBottom: "20px"}}>
                <Box 
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: 500,
                        mx: 'auto'
                    }}
                    >
                    <TextField
                        label="Nome do Arquivo"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Descrição do Arquivo"
                        variant="outlined"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <UploadFile
                        setDataFile={setDataFile}
                    />
                    {dataFile && (
                        <Grid item xs={12} sx={{marginTop: "30px"}}>
                            <Grid container justifyContent="center">
                                <Paper sx={{...paperStyle, width: "1300"}}>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="start" alignItems="center">
                                            <Paper sx={{width: "259px",
                                                        height: "240px",
                                                        backgroundColor: "#364257",
                                                        border: "2px solid #454545",
                                                        boxShadow: "0px 4px 8px rgba(0, 0, 5, 5)"
                                            }}>
                                                <Grid container justifyContent="center" alignItems="center">
                                                <Typography variant="h5" gutterBottom sx={{color: '#FFF'}}>
                                                    Nome do Arquivo:
                                                </Typography>
                                                <Typography variant="h6" gutterBottom sx={{color: '#C8C8C8', marginLeft: "10px"}}>
                                                    {dataFile.name}
                                                </Typography>
                                                <Typography variant="h5" gutterBottom sx={{color: '#FFF', marginTop: "50px"}}>
                                                    Tamanho do Arquivo:
                                                </Typography>
                                                <Typography variant="h6" gutterBottom sx={{color: '#C8C8C8', marginLeft: "10px"}}>
                                                    {dataFile.size}
                                                </Typography>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                    <Button type="submit" fullWidth sx={buttonStyle}>Adicionar Item</Button>
                </Box>
            </Paper>
            <NotificationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                title="Notificação"
            >
                {modalContent}
            </NotificationModal>
        </React.Fragment>
    );
}
