import React, { useEffect } from "react";
import MenuTab from "../components/Menu";
import Grid from "@mui/material/Grid";
import { stylePage, paperStyle, buttonStyle } from "../utils/utils";
import { getDocuments } from "../axios";
import LoadingScreen from "../components/LoadingScreen";
import DataList from "../components/Lists/ListCompDocs";
import { Button, Paper } from "@mui/material";
import FormDocs from "../components/Forms/FormDocs";
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default function Documents(props) {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState(false);

    useEffect(() => {
        getDataDocuments();
    }, [form]);
    
    const getDataDocuments = async () => {
        setLoading(true);

        try {
            const response = await getDocuments();
            setData(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleForm = () => {
        setForm(!form);
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <React.Fragment>
            <MenuTab />
            <Grid container sx={stylePage}>
                <Paper sx={{...paperStyle, marginBottom: "20px"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained"
                                sx={buttonStyle}
                                onClick={handleForm}
                                startIcon={<AddCircleIcon />}
                            >
                                Adicionar Documento
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                {form ? <FormDocs
                            getDataDocuments={getDataDocuments}
                            setForm={setForm} /> : <DataList data={data} getDataDocuments={getDataDocuments}
                        />
                }
            </Grid>
        </React.Fragment>
    );
}