export const paperStyle = {
	display: "flex",
	borderRadius: 2,
    backgroundColor: "rgba(211, 211, 211, 0.3)",
    width: "100%",
    height: "100%",
    color: "black",
    borderColor: "black",
};

export const paperStyleTop = {
	p: 2,
	display: "flex",
	borderRadius: 2,
    backgroundColor: "rgba(211, 211, 211, 0.1)",
    width: "70%",
    height: "100%",
    flexShrink: 0,
    color: "black",
    margin: "20px",
    borderColor: "black",
};

export const TextStyle = {
    fontSize: 28,
    fontFamily: 'Readex Pro',
    fontWeight: '600',
    textTransform: 'uppercase',
};

export const GridStyle = {
    width: "auto",
    height: "100%", 
};

export const buttonStyle = {
    color: "white",
    background: "linear-gradient(to bottom, #D62634, #5D327E)",
    margin: "10px",
    boxShadow: "0 1px 5px 2px rgba(255, 105, 135, .3)",
    minWidth: "100px", // Largura mínima do botão para acomodar o texto
    whiteSpace: "nowrap", // Evita que o texto seja quebrado em várias linhas
    overflow: "hidden", // Esconde qualquer conteúdo que ultrapasse o limite do botão
    textOverflow: "ellipsis", // Adiciona reticências (...) para indicar que o texto foi cortado
    display: "inline-block", // Garante que o botão ajuste seu tamanho conforme o conteúdo
    textAlign: "center", // Centraliza o texto dentro do botão
    padding: "8px 16px", // Adiciona um preenchimento interno ao botão
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
};


export const stylePage = {
    marginTop: "60px",
    padding: "20px",
};