import React, { useEffect, useState } from 'react';
import MenuTab from '../components/Menu';
import Grid from '@mui/material/Grid';
import DataList from '../components/Lists/ListCompExe';
import { stylePage } from '../utils/utils';
import { Button, Paper } from '@mui/material';
import FormVersion from '../components/Forms/FormExe';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { buttonStyle, paperStyle } from '../utils/utils';
import { getExe } from '../axios';
import LoadingScreen from '../components/LoadingScreen';


export default function Executes() {
    const [data, setData] = useState([]);
    const [form, setForm] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDataExe();
    }, []);
    
    const getDataExe = async () => {
        try {
            const response = await getExe();
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleForm = () => {
        setForm(!form);
    };

    if (loading) {
        return (
            <LoadingScreen />
        );
    }

    return (
        <React.Fragment>
            <MenuTab />
            <Grid container sx={stylePage}>
                <Paper sx={{...paperStyle, marginBottom: "20px"}}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Button 
                                variant="contained"
                                sx={buttonStyle}
                                onClick={handleForm}
                                startIcon={<AddCircleIcon />}
                            >
                                Adicionar Executável
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                {form ? <FormVersion
                            getDataExe={getDataExe}
                            setForm={setForm} /> : <DataList data={data} getDataExe={getDataExe}
                        />
                }
            </Grid>
        </React.Fragment>
    );
};
