import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { deleteBackup, updateBackup } from '../../axios';
import UploadFile from '../UploadFile';
import { NotificationModal } from '../NotificationModal';
import LoadingScreen from '../LoadingScreen';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export default function DataList(props) {
    const {getDataBackup} = props;
    const [editRowId, setEditRowId] = useState(null);
    const [draftData, setDraftData] = useState({});
    const [dataFile, setDataFile] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, setLoaging] = useState(false);

    const handleOpenModal = (message) => {
        setModalContent(message);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const startEdit = (row) => {
        setEditRowId(row.name);
        setDraftData(row);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setDraftData({});
    };

    const handleEditChange = (field, value) => {
        setDraftData(prev => ({ ...prev, [field]: value }));
    };

    const saveEdit = () => {
        setEditRowId(null);

        const formData = new FormData();
        setLoaging(true);
        formData.append('name', draftData.name);
        formData.append('date', draftData.date);
        if (dataFile)
            formData.append('file', dataFile);

        try {
            updateBackup(draftData.id, formData);
            handleOpenModal("Backup atualizado com sucesso!");
        } catch (error) {
            handleOpenModal("Erro ao atualizar backup!");
        }
        setTimeout(() => {
            getDataBackup();
        }, 1000);
        setLoaging(false);
    };

    const handleDelete = async (row) => {
        try {
            await deleteBackup(row.id);
            handleOpenModal("Backup deletado com sucesso!");
        } catch (error) {
            handleOpenModal("Erro ao deletar backup!");
        }
        setTimeout(() => {
            getDataBackup();
        }, 1000);
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{backgroundColor: "rgba(211, 211, 211, 0.3)"}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: "20px" }}>Nome Arquivo</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Data de Criação</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Baixar Arquivo</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Tamanho</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.data.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {editRowId === row.name ? 
                                <TextField value={draftData.name} onChange={e => handleEditChange('name', e.target.value)} />
                                : row.name}
                            </TableCell>
                            <TableCell align='left'>
                                {editRowId === row.name ? 
                                <TextField value={draftData.date} onChange={e => handleEditChange('date', e.target.value)} />
                                : row.date}
                            </TableCell>
                            <TableCell align="Center">
                                {editRowId === row.name ?
                                    (dataFile ? 
                                        dataFile.name :
                                        <UploadFile setDataFile={setDataFile} />
                                    )
                                    :
                                    <IconButton aria-label="download" href={`${API_BASE_URL}${row.file}`} target="_blank" download>
                                        <DownloadIcon sx={{color: "blue"}}/>
                                    </IconButton>
                                }
                            </TableCell>
                            <TableCell align="Center" sx={{fontSize: "15px"}}>{row.size}</TableCell>
                            <TableCell align="Center">
                                {editRowId === row.name ? (
                                <>
                                    <IconButton onClick={saveEdit} color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={cancelEdit} color="secondary">
                                        <CancelIcon />
                                    </IconButton>
                                </>
                                ) : (
                                <>
                                    <IconButton onClick={() => startEdit(row)} color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(row)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NotificationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                name="Notificação"
            >
                {modalContent}
            </NotificationModal>
        </React.Fragment>
    );
}
