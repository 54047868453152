import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Box,
  TableHead
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFile from '../UploadFile';
import { updateSupportFile, deleteSupportFile, updateExe, deleteExe, postSupportFile } from '../../axios';
import LoadingScreen from '../LoadingScreen';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddSupportModal from '../AddSupportModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function ProgramList(props) {
    const [editRowId, setEditRowId] = useState(null);
    const [draftData, setDraftData] = useState({});
    const [dataFile, setDataFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const programs = props.data.reduce((acc, item) => {
        acc[item.program] = acc[item.program] || [];
        acc[item.program].push(item);
        return acc;
    }, {});

    const startEdit = (item, type) => {
        setEditRowId(`${type}-${item.id}`);
        setDraftData(item);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setDraftData({});
    };

    const handleEditChange = (field, value) => {
        setDraftData(prev => ({ ...prev, [field]: value }));
    };

    const saveEdit = (item) => {
        setEditRowId(null);

        const formData = new FormData();
        formData.append('name', draftData.name);
        formData.append('description', draftData.description);
        if (dataFile)
            formData.append('file', dataFile);

        try {
            updateExe(item.id, formData);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setDraftData({});
            setDataFile(null);
        }
        setTimeout(() => {
            props.getDataExe();
        }, 1000);
    };

    const saveEditSupport = (item) => {
        setEditRowId(null);

        const formData = new FormData();
        formData.append('name', draftData.name);
        formData.append('description', draftData.description);
        formData.append('execute', item.id);
        if (dataFile)
            formData.append('file', dataFile);

        try {
            updateSupportFile(item.id, formData);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setDraftData({});
            setDataFile(null);
        }
        setTimeout(() => {
            props.getDataExe();
        }, 1000);
    };

    const handleDelete = async (item) => {
        try {
            await deleteExe(item.id);
            setTimeout(() => {
                props.getDataExe();
            }, 1000);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteSupport = async (item) => {
        try {
            await deleteSupportFile(item.id);
            setTimeout(() => {
                props.getDataExe();
            }, 1000);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOpenModal = (item) => {
        setDraftData(item);
        setModalOpen(true);
    };
    
      const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleAddSupport = (formData) => {
        formData.append('execute', draftData.id);

        try {
            postSupportFile(formData);
        } catch (error) {
            console.log(error);
        } finally {
            setDraftData({});
            setDataFile(null);
        }
        setTimeout(() => {
            props.getDataExe();
        }, 1000);
        handleCloseModal();
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <React.Fragment>
            <AddSupportModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleAddSupport}
            />
            <Box sx={{width: '100%'}}>
                {Object.keys(programs).map((program, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel-content"
                            id="panel-header"
                            sx={{
                                backgroundColor: '#f0f0f0',
                                borderBottom: '1px solid #000',
                                width: '100%'
                            }}
                        >
                            <Typography variant="h6">Arquivos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome do Programa</TableCell>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell>Arquivo</TableCell>
                                            <TableCell>Criado em</TableCell>
                                            <TableCell>Atualizado em</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {programs[program].map((item, index) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    {editRowId === `exe-${item.id}` ?
                                                        <TextField
                                                            value={draftData.name}
                                                            onChange={e => handleEditChange('name', e.target.value)}
                                                        /> :
                                                        item.name
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {editRowId === `exe-${item.id}` ?
                                                        <TextField
                                                            value={draftData.description}
                                                            onChange={e => handleEditChange('description', e.target.value)}
                                                        /> :
                                                        item.description
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {editRowId === `exe-${item.id}` ?
                                                        (dataFile ?
                                                            dataFile.name :
                                                            <UploadFile setDataFile={setDataFile} />
                                                        ) :
                                                        <IconButton aria-label="download" href={`${API_BASE_URL}${item.file}`} target="_blank" download>
                                                            <DownloadIcon sx={{color: "blue"}}/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>{item.created_at}</TableCell>
                                                <TableCell>{item.updated_at}</TableCell>
                                                <TableCell>
                                                    {editRowId === `exe-${item.id}` ?
                                                        <>
                                                            <IconButton onClick={() => {
                                                                saveEdit(item);
                                                            }} color="primary">
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => {
                                                                cancelEdit();
                                                            }} color="error">
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </> :
                                                        <>
                                                            <IconButton onClick={() => {
                                                                handleOpenModal(item);
                                                            }} color="success">
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => startEdit(item, 'exe')} color="primary">
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleDelete(item)} color="error">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {programs[program].map((executable, index) => (
                                <Accordion key={index} defaultExpanded={false}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-content-support"
                                        id={`panel-header-support-${executable.name}`}
                                    >
                                        <Typography variant="subtitle1" sx={{}}>Arquivos de Suporte</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nome do Programa</TableCell>
                                                        <TableCell>Descrição</TableCell>
                                                        <TableCell>Arquivo</TableCell>
                                                        <TableCell>Criado em</TableCell>
                                                        <TableCell>Atualizado em</TableCell>
                                                        <TableCell>Ações</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {executable.supports.map((support, idx) => (
                                                        <TableRow key={support.id}>
                                                            <TableCell>
                                                                {editRowId === `sup-${support.id}` ?
                                                                    <TextField
                                                                        value={draftData.name}
                                                                        onChange={e => handleEditChange('name', e.target.value)}
                                                                    /> :
                                                                    support.name
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {editRowId === `sup-${support.id}` ?
                                                                    <TextField
                                                                        value={draftData.description}
                                                                        onChange={e => handleEditChange('description', e.target.value)}
                                                                    /> :
                                                                    support.description
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {editRowId === `sup-${support.id}` ?
                                                                    (dataFile ?
                                                                        dataFile.name :
                                                                        <UploadFile setDataFile={setDataFile} />
                                                                    ) :
                                                                    <IconButton aria-label="download" href={`API_BASE_URL${support.file}`} target="_blank" download>
                                                                        <DownloadIcon sx={{color: "blue"}}/>
                                                                    </IconButton>
                                                                }
                                                            </TableCell>
                                                            <TableCell>{support.created_at}</TableCell>
                                                            <TableCell>{support.updated_at}</TableCell>
                                                            <TableCell>
                                                                {editRowId === `sup-${support.id}` ?
                                                                    <>
                                                                        <IconButton onClick={() => {
                                                                            saveEditSupport(support);
                                                                            setEditRowId(null);
                                                                        }} color="primary">
                                                                            <SaveIcon />
                                                                        </IconButton>
                                                                        <IconButton onClick={() => {
                                                                            setEditRowId(null);
                                                                            setDraftData({});
                                                                            setDataFile(null);
                                                                        }} color="error">
                                                                            <CancelIcon />
                                                                        </IconButton>
                                                                    </> :
                                                                    <>
                                                                        <IconButton onClick={() => startEdit(support, 'sup')} color="primary">
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton onClick={() => handleDeleteSupport(support)} color="error">
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </React.Fragment>
    );
}
