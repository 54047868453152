import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './containers/Login';
import Home from './containers/Home';
import Scripts from './containers/Scripts';
import Credentials from './containers/Credentials';
import Version from './containers/Versions';
import Executes from './containers/Executes';
import Documents from './containers/Documents';
import Backup from './containers/Backup';

 
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }/>
        <Route path="/scripts" element={
          <ProtectedRoute>
            <Scripts />
          </ProtectedRoute>
        }/>
        <Route path="/credentials" element={
          <ProtectedRoute>
            <Credentials />
          </ProtectedRoute>
        }/>
        <Route path="/versions" element={
          <ProtectedRoute>
            <Version />
          </ProtectedRoute>
        }/>
        <Route path="/executes" element={
          <ProtectedRoute>
            <Executes />
          </ProtectedRoute>
        }/>
        <Route path="/documents" element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }/>
        <Route path="/backup" element={
          <ProtectedRoute>
            <Backup />
          </ProtectedRoute>
        }/>
      </Routes>
    </BrowserRouter>
  );
}
