import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { deleteDocument, updateDocument } from '../../axios';
import UploadFile from '../UploadFile';
import { NotificationModal } from '../NotificationModal';
import LoadingScreen from '../LoadingScreen';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function DataList(props) {
    const {getDataDocuments} = props;
    const [editRowId, setEditRowId] = useState(null);
    const [draftData, setDraftData] = useState({});
    const [dataFile, setDataFile] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, setLoaging] = useState(false);

    const handleOpenModal = (message) => {
        setModalContent(message);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const startEdit = (row) => {
        setEditRowId(row.title);
        setDraftData(row);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setDraftData({});
    };

    const handleEditChange = (field, value) => {
        setDraftData(prev => ({ ...prev, [field]: value }));
    };

    const saveEdit = () => {
        setEditRowId(null);

        const formData = new FormData();
        setLoaging(true);
        formData.append('title', draftData.title);
        formData.append('description', draftData.description);
        if (dataFile)
            formData.append('file', dataFile);

        try {
            updateDocument(draftData.id, formData);
            handleOpenModal("Documento atualizado com sucesso!");
        } catch (error) {
            handleOpenModal("Erro ao atualizar documento!");
        }
        setTimeout(() => {
            getDataDocuments();
        }, 1000);
        setLoaging(false);
    };

    const handleDelete = async (row) => {
        try {
            await deleteDocument(row.id);
            handleOpenModal("Documento deletado com sucesso!");
        } catch (error) {
            handleOpenModal("Erro ao deletar documento!");
        }
        setTimeout(() => {
            getDataDocuments();
        }, 1000);
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{backgroundColor: "rgba(211, 211, 211, 0.3)"}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: "20px" }}>Nome Arquivo</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Descrição</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Data de Criação</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Última Atualização</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Baixar Arquivo</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.data.map((row) => (
                        <TableRow key={row.title}>
                            <TableCell component="th" scope="row">
                                {editRowId === row.title ? 
                                <TextField value={draftData.title} onChange={e => handleEditChange('name', e.target.value)} />
                                : row.title}
                            </TableCell>
                            <TableCell align='left'>
                                {editRowId === row.title ? 
                                <TextField value={draftData.description} onChange={e => handleEditChange('description', e.target.value)} />
                                : row.description}
                            </TableCell>
                            <TableCell align="Center" sx={{fontSize: "15px"}}>{row.created_at}</TableCell>
                            <TableCell align="Center" sx={{fontSize: "15px"}}>{row.updated_at}</TableCell>
                            <TableCell align="Center">
                                {editRowId === row.title ?
                                    (dataFile ? 
                                        dataFile.name :
                                        <UploadFile setDataFile={setDataFile} />
                                    )
                                    :
                                    <IconButton aria-label="download" href={`${API_BASE_URL}${row.file}`} target="_blank" download>
                                        <DownloadIcon sx={{color: "blue"}}/>
                                    </IconButton>
                                }
                            </TableCell>
                            <TableCell align="Center">
                                {editRowId === row.title ? (
                                <>
                                    <IconButton onClick={saveEdit} color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={cancelEdit} color="secondary">
                                        <CancelIcon />
                                    </IconButton>
                                </>
                                ) : (
                                <>
                                    <IconButton onClick={() => startEdit(row)} color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(row)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NotificationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                title="Notificação"
            >
                {modalContent}
            </NotificationModal>
        </React.Fragment>
    );
}
