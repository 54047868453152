import React from "react";
import MenuTab from "../components/Menu";
import Grid from "@mui/material/Grid";
import { stylePage } from "../utils/utils";


export default function Home(props) {
    return (
        <React.Fragment>
            <MenuTab />
            <Grid container sx={stylePage}>
                <Grid item xs={12}>
                    <h1>Home</h1>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}