import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { deleteCredential, updateCredential } from '../../axios';
import { NotificationModal } from '../NotificationModal';
import LoadingScreen from '../LoadingScreen';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function DataList(props) {
    const {getDataCredentials} = props;
    const [editRowId, setEditRowId] = useState(null);
    const [draftData, setDraftData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [loading, setLoaging] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState({});

    const handleVisibilityToggle = (login) => {
        setPasswordVisibility(prevState => ({
          ...prevState,
          [login]: !prevState[login]
        }));
    };

    const handleOpenModal = (message) => {
        setModalContent(message);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const startEdit = (row) => {
        setEditRowId(row.login);
        setDraftData(row);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setDraftData({});
    };

    const handleEditChange = (field, value) => {
        setDraftData(prev => ({ ...prev, [field]: value }));
    };

    const saveEdit = () => {
        setEditRowId(null);
        setLoaging(true);
        let data = {
            "login": draftData.login,
            "password": draftData.password,
            "description": draftData.description,
            "domain": draftData.domain
        }

        try {
            updateCredential(draftData.id, data);
            handleOpenModal("Credencial atualizada com sucesso!");
        } catch (error) {
            console.log(error);
            handleOpenModal("Erro ao atualizar credencial!");
        }
        setTimeout(() => {
            getDataCredentials();
        }, 1000);
        setLoaging(false);
    };

    const handleDelete = async (row) => {
        try {
            await deleteCredential(row.id);
            handleOpenModal("Script deletado com sucesso!");
        } catch (error) {
            console.log(error);
            handleOpenModal("Erro ao deletar script!");
        }
        setTimeout(() => {
            getDataCredentials();
        }, 1000);
    }

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{backgroundColor: "rgba(211, 211, 211, 0.3)"}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', fontSize: "20px" }}>Login</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Senha</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Descrição</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Dominio</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Data de Criação</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Última Atualização</TableCell>
                            <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.data.map((row) => (
                        <TableRow key={row.login}>
                            <TableCell component="th" scope="row">
                                {editRowId === row.login ? 
                                <TextField value={draftData.login} onChange={e => handleEditChange('login', e.target.value)} />
                                : row.login}
                            </TableCell>
                            <TableCell align='left'>
                                {editRowId === row.login ? 
                                    <TextField 
                                    type={passwordVisibility[row.login] ? 'text' : 'password'}
                                    value={draftData.password}
                                    onChange={e => handleEditChange('password', e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleVisibilityToggle(row.login)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end"
                                            >
                                            {passwordVisibility[row.login] ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    />
                                    : <span>
                                        {passwordVisibility[row.login] ? row.password : '••••••••'}
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => handleVisibilityToggle(row.login)}
                                        onMouseDown={e => e.preventDefault()}
                                        edge="end"
                                        >
                                        {passwordVisibility[row.login] ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </span>
                                }
                                </TableCell>
                            <TableCell align='left'>
                                {editRowId === row.login ? 
                                <TextField value={draftData.description} onChange={e => handleEditChange('description', e.target.value)} />
                                : row.description}
                            </TableCell>
                            <TableCell align='left'>
                                {editRowId === row.login ? 
                                    <TextField value={draftData.domain} onChange={e => handleEditChange('dominio', e.target.value)} />
                                    : <Link href={`${API_BASE_URL}${row.domain}`} target="_blank" rel="noopener noreferrer">{row.domain}</Link>}
                            </TableCell>
                            <TableCell align="Center" sx={{fontSize: "15px"}}>{row.created_at}</TableCell>
                            <TableCell align="Center" sx={{fontSize: "15px"}}>{row.updated_at}</TableCell>
                            <TableCell align="Center">
                                {editRowId === row.login ? (
                                <>
                                    <IconButton onClick={saveEdit} color="primary">
                                    <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={cancelEdit} color="secondary">
                                    <CancelIcon />
                                    </IconButton>
                                </>
                                ) : (
                                <>
                                    <IconButton onClick={() => startEdit(row)} color="primary">
                                    <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(row)} color="error">
                                    <DeleteIcon />
                                    </IconButton>
                                </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NotificationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                title="Notificação"
            >
                {modalContent}
            </NotificationModal>
        </React.Fragment>
    );
}
