import React, {useState} from 'react';
import { Button, TextField, Box, Paper } from '@mui/material';
import { buttonStyle, paperStyle } from '../../utils/utils';
import { postCredential } from '../../axios';
import { NotificationModal } from '../NotificationModal';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default function FormCred(props) {
    const [description, setDescription] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [domain, setDomain] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault(); // Isso impede que o botão perca o foco
    };

    const handleOpenModal = (message) => {
        setModalContent(message);
        setModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setModalOpen(false);
      };

    const handleSubmit = (event) => {
        event.preventDefault();

        let data = {
            'description': description,
            'login': login,
            'password': password,
            'domain': domain
        };
        
        try {
            const response = postCredential(data);
            console.log(response);
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                props.getDataCredentials();
            }, 1000);
            props.setForm(false);
            setDescription('');
            setLogin('');
            setPassword('');
            setDomain('');
        }
    };

    return (
        <React.Fragment>
            <Paper sx={{...paperStyle, marginBottom: "20px"}}>
                <Box 
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: 500,
                        mx: 'auto'
                    }}
                    >
                    <TextField
                        label="Login"
                        variant="outlined"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Senha"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label="Descrição"
                        variant="outlined"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Dominio"
                        variant="outlined"
                        type="text"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Button type="submit" fullWidth sx={buttonStyle}>Adicionar Item</Button>
                </Box>
            </Paper>
            <NotificationModal
                open={modalOpen}
                handleClose={handleCloseModal}
                title="Notificação"
            >
                {modalContent}
            </NotificationModal>
        </React.Fragment>
    );
}
