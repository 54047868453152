import axios from 'axios';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const getConfig = () => {
  const token = localStorage.getItem('userToken');
  const csrfToken = Cookies.get('csrftoken');
  return {
    headers: { 
      'Authorization': `Bearer ${token}`,
      'X-CSRFToken': csrfToken,
      'Content-Type': 'application/json'
    }
  };
};


export const userLogin = async (username, password) => {  
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_CRYPTOGRAPHY);
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY_CRYPTOGRAPHY);

  const encryptedPassword = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();

  try {
    console.log(`${API_BASE_URL}/users/login`)
    const response = await axios.post(`${API_BASE_URL}/users/login`, { username, password: encryptedPassword }, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getScripts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/scripts/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postScript = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/scripts/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteScript = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/scripts/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateScript = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/scripts/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getCredentials = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/credentials/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postCredential = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/credentials/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateCredential = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/credentials/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteCredential = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/credentials/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getVersions = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/versions/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postVersion = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/versions/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateVersion = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/versions/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteVersion = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/versions/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getExe = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/executes/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postExe = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/executes/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateExe = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/executes/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteExe = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/executes/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getSupportFiles = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/executes/supports`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postSupportFile = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/executes/supports`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateSupportFile = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/executes/supports/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteSupportFile = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/executes/supports/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getDocuments = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/documents/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postDocument = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/documents/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateDocument = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/documents/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteDocument = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/documents/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const getBackup = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/backups/all`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const postBackup = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/backups/all`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const updateBackup = async (id, data) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/backups/all/${id}`, data, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};


export const deleteBackup = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/backups/all/${id}`, getConfig());
    return response;
  } catch (error) {
    throw error;
  }
};