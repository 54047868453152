import React, {useState} from 'react';
import { Accordion,
        AccordionSummary,
        AccordionDetails,
        Typography,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableRow,
        Paper,
        Link,
        Box,
        TableHead,
        TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFile from '../UploadFile';
import { updateVersion, deleteVersion } from '../../axios';
import LoadingScreen from '../LoadingScreen';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function ProgramList(props) {
    const [editRowId, setEditRowId] = useState(null);
    const [draftData, setDraftData] = useState({});
    const [dataFile, setDataFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const programs = props.data.reduce((acc, item) => {
        acc[item.program] = acc[item.program] || [];
        acc[item.program].push(item);
        return acc;
    }, {});

    const startEdit = (item) => {
        setEditRowId(item.id);
        setDraftData(item);
    };

    const cancelEdit = () => {
        setEditRowId(null);
        setDraftData({});
    };

    const handleEditChange = (field, value) => {
        setDraftData(prev => ({ ...prev, [field]: value }));
    };

    const saveEdit = (item) => {
        setEditRowId(null);

        const formData = new FormData();
        formData.append('version', draftData.version);
        formData.append('file_name', draftData.file_name);
        formData.append('description', draftData.description);
        if (dataFile)
            formData.append('file', dataFile);

        try {
            updateVersion(item.id, formData);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setDraftData({});
            setDataFile(null);
        }
        setTimeout(() => {
            props.getDataVersions();
        }, 1000);
    };

    const handleDelete = async (item) => {
        try {
            await deleteVersion(item.id);
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            props.getDataVersions();
        }, 1000);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Box sx={{width: '100%'}}>
            {Object.keys(programs).map((program, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-content"
                        id="panel-header"
                        sx={{
                            backgroundColor: '#f0f0f0',
                            borderBottom: '1px solid #000',
                            width: '100%'
                        }}
                    >
                        <Typography variant="h6">{program}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: "20px" }}>Versão</TableCell>
                                        <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Nome do Arquivo</TableCell>
                                        <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Baixar Arquivo</TableCell>
                                        <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Data de Criação</TableCell>
                                        <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Última Atualização</TableCell>
                                        <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Descrição</TableCell>
                                        <TableCell align="Center" sx={{ fontWeight: 'bold', fontSize: "20px" }}>Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {programs[program].map((item, index) => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                {editRowId === item.id ?
                                                    <TextField
                                                        value={draftData.version}
                                                        onChange={e => handleEditChange('version', e.target.value)}
                                                    /> :
                                                    item.version
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {editRowId === item.id ? 
                                                    <TextField value={draftData.file_name} onChange={e => handleEditChange('file_name', e.target.value)} />
                                                : item.file_name}
                                            </TableCell>
                                            <TableCell align="Center">
                                                {editRowId === item.id ?
                                                    (dataFile ? 
                                                        dataFile.name :
                                                        <UploadFile setDataFile={setDataFile} />
                                                    )
                                                    :
                                                    <IconButton aria-label="download" href={`${API_BASE_URL}${item.file_url}`} target="_blank" download>
                                                        <DownloadIcon sx={{color: "blue"}}/>
                                                    </IconButton>
                                                }
                                            </TableCell>
                                            <TableCell>{item.created_at}</TableCell>
                                            <TableCell>{item.updated_at}</TableCell>
                                            <TableCell>
                                                {editRowId === item.id ? 
                                                    <TextField value={draftData.description} onChange={e => handleEditChange('description', e.target.value)} />
                                                : item.description}
                                            </TableCell>
                                            <TableCell>
                                                {editRowId === item.id ? (
                                                    <>
                                                        <IconButton onClick={() => saveEdit(item)} color="primary">
                                                            <SaveIcon />
                                                        </IconButton>
                                                        <IconButton onClick={cancelEdit} color="secondary">
                                                            <CancelIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton onClick={() => startEdit(item)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleDelete(item)} color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}
